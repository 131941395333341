import React from "react";
import moment from "moment-mini";
import { INewReservationDetailsProps } from "./NewReservationDetailsProps";
import { StyledContainer } from "./NewReservationDetailsHelpers";
import { StaticImage } from "gatsby-plugin-image";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown, faChevronUp } from "@fortawesome/free-solid-svg-icons";
import Row from "react-bootstrap/Row";
import { useAppDispatch, useAppSelector } from "../../../hooks";
import { setEditReservationCheckout } from "../../../redux/slices/Checkout/editReservationCheckout";
import { resDisplayDateFormat } from "../../../services/dates";
import Title4 from "../../global/Title4/Title4";
import useMobileDetect from "../../../hooks/useMobileDetect";
import { reservationHeading } from "./NewReservationDetails.module.scss";
import { navigate } from "gatsby";
const NewReservationDetails: React.FC<INewReservationDetailsProps> = ({
  step,
  reservation,
  hotel,
  handleChangeDate,
}) => {
  const dispatch = useAppDispatch();
  const checkout: { [x: string | number]: any } = useAppSelector(
    (state) => state.editReservationCheckout
  );
  const index = Object.keys(checkout.Rooms)[0];
  const selectedRoom = checkout.Rooms[index];
  const roomImage =
    selectedRoom.room?.imageUrls && selectedRoom.room?.imageUrls.length
      ? selectedRoom.room?.imageUrls[0]
      : "";
  const adults = selectedRoom.adults || 1;
  const children = selectedRoom.children || 0;
  const isMobileOnly = useMobileDetect();
  const [showReservationDetail, setshowReservationDetail] = React.useState(
    step === "select-room"
  );
  let guests = adults > 1 ? `${adults} Adults` : "1 Adult";
  if (children >= 1) {
    const childrenStr = children > 1 ? `${children} Children` : "1 Child";
    guests += `, ${childrenStr}`;
  }

  const stayDateStr = () => {
    if (reservation.start) {
      const arrival = moment(checkout.Start, "YYYY-M-D");
      const departure = moment(checkout.End, "YYYY-M-D");
      const displayArrival = arrival.format(resDisplayDateFormat);
      const displayDeparture = departure.format(resDisplayDateFormat);
      return `${displayArrival} - ${displayDeparture}`;
    } else {
      return "";
    }
  };

  const handleButtonClick = () => {
    isMobileOnly
      ? dispatch(
          setEditReservationCheckout({
            ...checkout,
            Step: "select-room",
            isModalOpen: true,
          })
        )
      : dispatch(
          setEditReservationCheckout({
            ...checkout,
            Step: "select-room",
            isModalOpen: false,
          })
        );

    navigate("/edit-reservation/select-room");
  };
  const handleShowReservationDetail = () => {
    setshowReservationDetail(!showReservationDetail);
  };
  return isMobileOnly ? (
    <StyledContainer>
      <Row className="image-hotel-wrapper g-0 m-1">
        <div className={"buttonParent"}>
          <div
            className={"reservationHeader"}
            onClick={handleShowReservationDetail}
          >
            <h5 id="ypur-reservation">Your Reservation</h5>
            <FontAwesomeIcon
              icon={showReservationDetail ? faChevronUp : faChevronDown}
            />
          </div>
        </div>
        <div
          className={`yourReservationDiv ${
            showReservationDetail ? "show" : ""
          }`}
        >
          <div className="mb-2" id="selected-hotel-name">
            {hotel.name}
          </div>
          <div className="mb-2" id="reservation-id">
            Reservation ID: {reservation.ID || reservation.iD}
          </div>
          <div className="mb-2" id="selected-dates">
            Selected Dates: {stayDateStr()}
          </div>
          {step !== "select-room" && (
            <>
              <Row className="mt-3 mb-3 res-detail g-0">
                <div>
                  <div className=" d-flex flex-row align-items-start">
                    <FontAwesomeIcon icon="bed" className="me-2" />
                    <div className="flex-grow-11 " id="selected-room">
                      {selectedRoom.room?.name}
                    </div>
                  </div>
                  <div id="guest-count">
                    <FontAwesomeIcon
                      icon="user-friends"
                      className="me-2 mt-2"
                    />
                    {guests}
                  </div>
                </div>
              </Row>
              <div className="image-wrapper" id="select-room-image">
                {roomImage ? (
                  <img src={roomImage} alt={selectedRoom.room?.name} />
                ) : (
                  <StaticImage
                    src="../../../images/hotel_fallback.jpg"
                    alt={selectedRoom.room?.name}
                    placeholder="blurred"
                    objectFit="cover"
                  />
                )}
              </div>
            </>
          )}
          <div
            className="changeDatesBtn"
            onClick={
              step === "select-room" ? handleChangeDate : handleButtonClick
            }
          >
            <button id="change-dates-button">Change Dates</button>
          </div>
        </div>
      </Row>
    </StyledContainer>
  ) : (
    <StyledContainer>
      <Row className="image-hotel-wrapper g-0">
        <Title4 className={reservationHeading}>Your Reservation</Title4>
        <div className={`yourReservationDiv show`}>
          <div className="mb-2" id="selected-hotel-name">
            {hotel.name}
          </div>
          <div className="mb-2" id="reservation-id">
            Reservation ID: {reservation.ID || reservation.iD}
          </div>
          <div className="mb-2" id="selected-dates">
            Selected Dates: {stayDateStr()}
          </div>
          <Row className="mt-3 mb-3 res-detail g-0">
            <div>
              <div className=" d-flex flex-row align-items-start">
                <FontAwesomeIcon icon="bed" className="me-3" />
                <div className="flex-grow-11 " id="selected-room">
                  {selectedRoom.room?.name}
                </div>
              </div>
              <div id="guest-count">
                <FontAwesomeIcon icon="user-friends" className="me-3" />
                {guests}
              </div>
            </div>
          </Row>
          <div className="image-wrapper" id="select-room-image">
            {roomImage ? (
              <img src={roomImage} alt={selectedRoom.room?.name} />
            ) : (
              <StaticImage
                src="../../../images/hotel_fallback.jpg"
                alt={selectedRoom.room?.name}
                placeholder="blurred"
                objectFit="cover"
              />
            )}
          </div>
          <div className="mb-3 changeDatesBtn" onClick={handleButtonClick}>
            <button id="change-dates-button">Change Dates</button>
          </div>
        </div>
      </Row>
    </StyledContainer>
  );
};

export default NewReservationDetails;
